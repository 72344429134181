.skills-container {
  width: 80vw;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 3rem;
    line-height: 20px;
  }

  .skills-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 50px;
    @include media(">=phone", "<lgphone") {
      grid-template-columns: 1fr;
    }
    @include media(">=lgphone", "<tablet") {
      grid-template-columns: 1fr;
    }
    @include media(">=tablet", "<desktop") {
      grid-template-columns: 1fr 1fr;
    }
    .skill {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;
      img {
        height: 3rem;
        margin-right: 40px;
      }
      p {
        font-size: 28px;
      }
    }
  }
}

.spinning {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
