.card {
  width: 100%;
  height: 250px;
  overflow: hidden !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  &:hover {
    opacity: 0.85;
  }
}

.content {
  overflow: hidden !important;
  font-family: "Open Sans";
  h1 {
    font-size: 14px;
    color: black;
  }
  p {
    font-size: 14px;
    color: black;
    margin-bottom: 20px;
  }
}
