@charset "utf-8";
@import "./include-media.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Open+Sans&display=swap");

// Atoms
@import "./card.scss";

// Components
@import "./navbar.scss";
@import "./header.scss";
@import "./work.scss";
@import "./about.scss";
@import "./skills.scss";
@import "./promotion.scss";
@import "./footer.scss";

* {
  font-family: "Montserrat";
}

body {
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.section {
  width: 100vw;
  height: auto;
  overflow: hidden;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.primary-btn {
  margin: 10px;
  padding: 10px;
  background-color: black;
  color: white;
  border: 0px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
}
